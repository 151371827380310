<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                <ValidationProvider name="Division" vid='division_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="division"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_level.division_id"
                      :options="divisionList"
                      id="division_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Region" vid='region_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="division"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('warehouse_config.region')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_level.region_id"
                      :options="regionLists"
                      id="region_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="District" vid='district_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="district"
                    slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                      {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_level.district_id"
                      :options="regionDistrictLists"
                      id="district_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Upazila" vid='upazila_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="upazila_id"
                    slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                      {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_level.upazilla_id"
                      :options="upazilaList"
                      id="upazila_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Union" vid='union_id' rules="">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="union_id"
                    slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                      {{ $t('globalTrans.union')}} <span class="text-danger"></span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_level.union_id"
                      :options="unionList"
                      id="union_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Warehouse" vid='warehouse_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="warehouse"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('warehouse_config.warehouse')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_level.warehouse_id"
                      :options="warehouseLists"
                      id="warehouse_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :disabled="isWareHouseUser"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    <div class="text-success" v-if="warehouseActualCapacity">
                      Warehouse actual space {{ warehouseActualCapacity }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <b-row v-for="(setLevel, index) in warehouse_level.level_info" :key="index">
                    <b-col lg="1" sm="1"></b-col>
                    <b-col lg="5" sm="5">
                        <ValidationProvider name="Level Name (En)" vid='level_name' rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="level_name"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{ $t('warehouse_config.level_namef')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="level_name"
                            v-model="setLevel.level_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="5" sm="5">
                         <ValidationProvider name="Level Name (Bn)" vid='current-price' rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="level_name_bn"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('warehouse_config.level_name_bn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="level_name_bn"
                            v-model="setLevel.level_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <!-- <b-col lg="4" sm="4">
                        <ValidationProvider name="Level Space" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="level_space"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('warehouse_config.level_space')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            type = "number"
                            id="level_space"
                            v-model="setLevel.level_space"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col> -->
                    <b-col lg="1" sm="1">
                        <b-button v-show="index == warehouse_level.level_info.length-1" variant=" iq-bg-success" size="sm" @click="add()"><i class="ri-add-line m-0"></i></b-button>
                        <b-button v-show="index || ( !index && warehouse_level.level_info.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </b-col>
                 </b-row>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { warehouseLevelStore, warehouseLevelUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getWarehouseLevelData()
      this.warehouse_level = tmp
    }
  },
  mounted () {
    core.index()
    if (this.isWareHouseUser) {
      this.warehouse_level.division_id = this.authUser.office_detail.division_id
      this.warehouse_level.region_id = this.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
      this.warehouse_level.district_id = this.authUser.office_detail.district_id
      this.warehouse_level.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
      this.warehouse_level.union_id = this.authUser.office_detail.union_id ?? 0
      const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
      this.warehouse_level.warehouse_id = warehouse.value
    }
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      warehouse_level: {
        division_id: '0',
        region_id: '0',
        district_id: '0',
        upazilla_id: '0',
        union_id: '0',
        warehouse_id: '0',
        level_info: [
          {
            level_name: '',
            level_name_bn: '',
            level_space: ''
          }
        ],
        status: 0,
        created_by: 1,
        updated_by: 1
      },
      regionDistrictLists: [],
      upazilaList: [],
      unionList: [],
      warehouseLists: [],
      warehouseActualCapacity: 0
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    regionLists: function () {
        return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    regionDetailList: function () {
      return this.$store.state.warehouse.regionDetailList
    }
  },
  watch: {
    'warehouse_level.region_id': function (newVal, oldVal) {
      this.getRegionDistrictList(newVal)
    },
    'warehouse_level.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'warehouse_level.upazilla_id': function (newVal, oldVal) {
        this.unionList = this.getUnionList(newVal)
      this.warehouseLists = this.getWarehouse(newVal, 'UPZ')
    },
    'warehouse_level.union_id': function (newVal, oldVal) {
      this.warehouseLists = this.getWarehouse(newVal, 'UP')
    },
    'warehouse_level.warehouse_id': function (newVal, oldVal) {
      this.warehouseActualCapacity = this.getWarehouseActualCapacity(newVal)
    }
  },
  methods: {
    add () {
      const stepName = {
        level_name: '',
        level_name_bn: '',
        level_space: ''
      }
      this.warehouse_level.level_info.push(stepName)
    },
    remove (key) {
      this.warehouse_level.level_info.splice(key, 1)
    },
    getWarehouseLevelData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
      if (this.warehouse_level.id) {
        result = await RestApi.putData(warehouseServiceBaseUrl, `${warehouseLevelUpdate}/${this.id}`, this.warehouse_level)
      } else {
        result = await RestApi.postData(warehouseServiceBaseUrl, warehouseLevelStore, this.warehouse_level)
      }

      loadingState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message
          })
        }
      }
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObject = response.filter(document => document.region_id === regionId)
          dataObject.map(obj => {
            const district = this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id)
            if (typeof district !== 'undefined') {
              const item = {
                value: obj.district_id,
                text: this.$i18n.locale === 'bn' ? district.text_bn : district.text_en
              }
              this.regionDistrictLists.push(item)
            }
          })
        }
      })
    },
    getUnionList (unionId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)

      if (unionId) {
        return unionList.filter(union => union.upazilla_id === unionId)
      }

      return unionList
    },
    getWarehouse (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
      }
      return list
    },
    getWarehouseActualCapacity (warehouseId) {
      const warehouse = this.$store.state.warehouse.warehouseInfoList.find(item => item.id === warehouseId)
      this.warehouseActualCapacity = warehouse.actual_capacity
    }
  }
}
</script>
