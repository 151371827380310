<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="11" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                <ValidationProvider name="Division" vid='division_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="division"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_level.division_id"
                      :options="divisionList"
                      id="division_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Region" vid='region_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="division"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('warehouse_config.region')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_level.region_id"
                      :options="regionLists"
                      id="region_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="District" vid='district_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="district"
                    slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                      {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_level.district_id"
                      :options="regionDistrictLists"
                      id="district_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Upazila" vid='upazila_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="upazila_id"
                    slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                      {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_level.upazilla_id"
                      :options="upazilaList"
                      id="upazila_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Warehouse" vid='warehouse_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="warehouse"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('warehouse_config.warehouse')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="warehouse_level.warehouse_id"
                      :options="warehouseLists"
                      id="warehouse_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Level Name" vid='level_name' rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="level_name"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('warehouse_config.level_name')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="level_name"
                      v-model="warehouse_level.level_name"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Level Name (bn)" vid='current-price' rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="level_name_bn"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{ $t('warehouse_config.level_name_bn')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="level_name_bn"
                      v-model="warehouse_level.level_name_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <!-- <ValidationProvider name="Level Space" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="level_space"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{ $t('warehouse_config.level_space')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="level_space"
                      v-model="warehouse_level.level_space"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider> -->
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-6')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { warehouseLevelStore, warehouseLevelUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getWarehouseLevelData()
      this.warehouse_level = tmp
    }
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      warehouse_level: {
        division_id: '0',
        region_id: '0',
        district_id: '0',
        upazilla_id: '0',
        warehouse_id: '0',
        level_name: '',
        level_name_bn: '',
        level_space: '',
        status: 0,
        created_by: 1,
        updated_by: 1
      },
      regionDistrictLists: [],
      upazilaList: [],
      unionList: [],
      warehouseLists: []
    }
  },
  computed: {
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    regionLists: function () {
        return this.$store.state.warehouse.regionList
    }
  },
  watch: {
    'warehouse_level.region_id': function (newVal, oldVal) {
      this.regionDistrictLists = this.getRegionDistrictList(newVal)
    },
    'warehouse_level.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'warehouse_level.upazilla_id': function (newVal, oldVal) {
      this.warehouseLists = this.getWarehouse(newVal)
    }
  },
  methods: {
    getWarehouseLevelData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
      if (this.warehouse_level.id) {
        result = await RestApi.putData(warehouseServiceBaseUrl, `${warehouseLevelUpdate}/${this.id}`, this.warehouse_level)
      } else {
        result = await RestApi.postData(warehouseServiceBaseUrl, warehouseLevelStore, this.warehouse_level)
      }

      loadingState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-6')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message
          })
        }
      }
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
            if (response) {
                const dataObject = response.filter(document => document.region_id === regionId)
                this.regionDistrictLists = dataObject.map(obj => {
                        if (this.$i18n.locale === 'bn') {
                            return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
                        } else {
                            return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
                        }
                })
            }
        })
    },
    getWarehouse (upzilaId = null) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList

      if (upzilaId) {
        return warehouseList.filter(warehouse => warehouse.upazilla_id === upzilaId)
      }

      return warehouseList
    }

  }
}
</script>
